@import "framework";

// Vars

$body-size: 14px;
$ref-width: 1366px;
$body-color: #575756;
$background-color: #e9dfd4;
$body-font-family: 'OptimaLTPro-Roman', 'Optima Regular', sans-serif;

$mobile: (body-size: 21px, ref-width: 800px, breakpoint: 700px);

// Global

body {
  background-color: $background-color;
  color: $body-color;
  font-family: $body-font-family;
  @include set-body-size;
  @include mobile {
    @include set-body-size;
  }
}

a {
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid $body-color;
    //text-decoration: underline;
  }
}
.home {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  .half-background {
    display: block;
    float: left;
    background-image: url(/placeholder/300-400-e9dfd4.svg);
    background-size: cover;
    width: 50%; height: 100%;
    @include portrait {
      width: 100%; height: 50%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }
  .mood-board { display: none; }
}

.menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: $background-color;
  padding: em(80px) em(180px) em(180px) em(180px);
  @include transform(translate(0%,100%));
  @include transition(0.5s);
  @include portrait {
    padding: em(40px);
  }
  &.active {
    @include transform(translate(0%,0%));
  }
  .contact {
    width: em(500px);
    line-height: 1.4285;
    @include portrait {
      width: auto;
      line-height: 1.6;
    }
  }
  .category {
    h1 {
      font-weight: normal;
      font-size: em(14px);
      margin: 0;
      padding-top: em(80px);
      padding-bottom: em(40px);
      @include mobile {
        font-size: 1em;
      }
    }
    ol li {
      font-size: em(34px);
      // font-family: 'AG Buch Regular','Arial', sans-serif;
      font-family: ag-book, sans-serif;
      font-weight: 300;
      font-style: normal;
      text-transform: uppercase;
      line-height: 1.2222;
      @include mobile {
        font-size: em(40px);
        line-height: 1.6;
      }
    }
  }
}
